import { Box, ScrollView } from "native-base";
import { MenuItem, Pagination, TextField } from "@mui/material";
import React from "react";

import { ContentContainer, CustomTabContainer, ListingTitle, Spacer } from "../../components";
import { ITabRoutes } from "../Dashboard";
import { h128, h2, h4, h8, w24 } from "../../styles";
import { SearchDashboard } from "./SearchDashboard";
import { ISearchDashboard } from "../../context";

interface IPlantOverview {
  children: JSX.Element;
  tabRoutes: ITabRoutes[];
  handleUpdateTab: (value: string) => void;
  handleUpdateSearchDashboard: (searchDashboard: ISearchDashboard) => void;
  activeTab: string;
}

export const PlantOverview = ({ children, tabRoutes, activeTab, handleUpdateTab, handleUpdateSearchDashboard }: IPlantOverview) => {
  return (
    <ContentContainer>
      <div
        style={{
          flex: 1,
          backgroundColor: "white",
          borderRadius: 12,
          // boxShadow: "40 40",
          borderWidth: 1,
          borderColor: "#31304D",
          borderStyle: "solid",
          height: h128,
        }}>
        <Box flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Box flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <CustomTabContainer activeTab={activeTab} handleUpdateTab={handleUpdateTab} tabRoutes={tabRoutes} />
          </Box>

          <Box flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <SearchDashboard onPress={handleUpdateSearchDashboard} />
            <Spacer space={w24} isHorizontal={true} />
          </Box>
        </Box>

        <div
          style={{
            backgroundColor: "#31304D",
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
            justifyContent: "center",
            alignItems: "center",
          }}>
          <ListingTitle />
          <Spacer space={h4} />
        </div>
      </div>

      <div style={{ overflowY: "scroll", height: window.innerHeight - h128, backgroundColor: "white" }}>{children}</div>
    </ContentContainer>
  );
};

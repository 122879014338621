import { Box, ScrollView, VStack } from "native-base";
import React, { useContext, useRef } from "react";

import { SampleCard } from "../../components";
import { GlobalContext, ISample } from "../../context";
import { Divider } from "@mui/material";

interface IOngoingListing {
  handleShowSampleDetail: (value: string) => void;
}

export const OngoingListing = ({ handleShowSampleDetail }: IOngoingListing) => {
  const scrollViewRef = useRef<typeof ScrollView | null>(null);
  // const isFocused = useIsFocused();
  // useEffect(() => {
  //   if (isFocused !== null && isFocused) {
  //     scrollViewRef.current?.scrollTo(0, 0, true);
  //   }
  // }, [isFocused]);

  const { globalState } = useContext(GlobalContext);
  const { allClientSamples, searchDashboard } = globalState;

  const sampleCardProps = {
    handleShowSampleDetail: handleShowSampleDetail,
  };

  const filterBySearch = (eachSample: ISample) => {
    if (searchDashboard.searchText !== "") {
      if (searchDashboard.byCategory === "sampleId") {
        return eachSample.sampleId.toLowerCase().includes(searchDashboard.searchText.toLowerCase());
      }

      return eachSample.sampleName.toLowerCase().includes(searchDashboard.searchText.toLowerCase());
    }

    return true;
  };

  return (
    <div>
      {allClientSamples
        .filter((eachSample) => eachSample.isCompleted === false)
        .filter(filterBySearch)
        .map((eachSample, index) => {
          return (
            <div key={`${index}${eachSample.sampleId}`}>
              <SampleCard isCompleted={eachSample.isCompleted} {...sampleCardProps} cardItem={eachSample} />
              {<Divider style={{ marginRight: 20, marginLeft: 20 }} />}
            </div>
          );
        })}
    </div>
  );
};

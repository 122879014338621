import React from "react";

interface IListingTextProps {
  label: string;
  itemWidth?: number | string;
  flexValue?: number;
  style?: React.CSSProperties;
  textColor?: string;
  textSize?: number;
}

export const ListingText: React.FC<IListingTextProps> = ({ label, flexValue, style, textColor, textSize }) => {
  const dynamicColor = textColor !== undefined ? { color: textColor } : { color: "#27272a" };
  const dynamicSize = textSize !== undefined ? { fontSize: textSize } : { fontSize: 16 };
  return (
    <div
      style={{
        flex: flexValue !== undefined ? flexValue : 1,
        flexWrap: "wrap",
        justifyContent: "start",
        textAlign: "start",
        ...style,
        // backgroundColor: "lightgreen"
      }}>
      <span style={{ fontWeight: "500", flexWrap: "wrap", ...dynamicSize, ...dynamicColor }}>{label}</span>
    </div>
  );
};

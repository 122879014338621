import { Box, Divider, HStack } from "native-base";
import React from "react";

import { ListingLabel } from "./ListingLabel";

export const ListingTitle = () => {
  return (
    <Box
      px="2"
      py="2"
      // borderRadius={4}
      mx="auto"
      // shadow={4}
      // backgroundColor="white"
      width={window.innerWidth - 250}>
      <HStack
        space={4}
        // justifyContent="space-between"
        alignItems="center">
        <ListingLabel label={"Sample ID"} customFlex={0.5} />
        <ListingLabel label={"Company Name"} customFlex={2} />
        <ListingLabel label={"Sample Name"} customFlex={0.5} />
        <ListingLabel label={"Sampling Date"} customFlex={0.5} />
        <ListingLabel label={"Received Date"} customFlex={0.5} />
        <ListingLabel label={"Sample Type"} customFlex={0.5} />
        <ListingLabel label={"Requestor"} customFlex={0.5} />
        <ListingLabel label={"Analysis Status"} customFlex={0.5} />
        <ListingLabel label={"Invoice Status"} customFlex={0.5} />
      </HStack>
    </Box>
  );
};

import { Box, ScrollView, VStack } from "native-base";
import React, { useContext } from "react";

import { SampleCard } from "../../components";
import { GlobalContext, ISample } from "../../context";
import { Divider } from "@mui/material";

interface ICompletedListing {
  handleShowSampleDetail: (value: string) => void;
}

export const CompletedListing = ({ handleShowSampleDetail }: ICompletedListing) => {
  const { globalState } = useContext(GlobalContext);
  const { allClientSamples, searchDashboard } = globalState;

  const sampleCardProps = {
    handleShowSampleDetail: handleShowSampleDetail,
  };

  const filterBySearch = (eachSample: ISample) => {
    if (searchDashboard.searchText !== "") {
      if (searchDashboard.byCategory === "sampleId") {
        return eachSample.sampleId.toLowerCase().includes(searchDashboard.searchText.toLowerCase());
      }

      return eachSample.sampleName.toLowerCase().includes(searchDashboard.searchText.toLowerCase());
    }

    return true;
  };

  return (
    <div>
      {allClientSamples
        .filter((eachSample) => eachSample.isCompleted === true)
        .filter(filterBySearch)
        .map((eachSample, index) => {
          return (
            <div key={`${index}${eachSample.sampleId}`}>
              <SampleCard isCompleted={eachSample.isCompleted} {...sampleCardProps} cardItem={eachSample} />
              {<Divider style={{ marginRight: 20, marginLeft: 20 }} />}
            </div>
          );
        })}
    </div>
  );
};

const LOCALHOST = "http://localhost:8001/";
const LIVE_URL = "https://js-mvp-be-petrolab-ten.vercel.app/";
const LIVE_URL_DEV = "https://js-mvp-be-petrolab.vercel.app/";

const ENV_CONFIG = {
  dev: LIVE_URL_DEV,
  prod: LIVE_URL,
};

const CURRENT_ENV = ENV_CONFIG.prod;

export const APP_VERSION = "v1.2.0"; // latest version as of 10 Nov 2024

export const AUTH_VERIFY_EMAIL = `${CURRENT_ENV}auth/verify-email`;
export const AUTH_LOGIN = `${CURRENT_ENV}auth/sign-in`;
export const AUTH_SIGNUP = `${CURRENT_ENV}auth/sign-up`;
export const RESET_PASSWORD = `${CURRENT_ENV}auth/reset-password`;

export const VERIFY_CLIENT_PERMISSION = `${CURRENT_ENV}auth/verify-client-permission`;
export const GET_CLIENT_PROFILE_BY_EMAIL = `${CURRENT_ENV}client/get-client-by-email`;
export const GET_ALL_SAMPLE_OF_CLIENT = `${CURRENT_ENV}sample/get-all-sample-of-client`;

export const GET_SAMPLE_BY_REF = `${CURRENT_ENV}sample/get-sample-by-ref`;

// export const GET_ALL_SAMPLE_IN_PLANT = `${CURRENT_ENV}sample/get-all-sample-in-plant`;
export const GET_ALL_SAMPLE_OF_AN_INDEX = `${CURRENT_ENV}sample/get-all-sample-of-an-index-sample`;
// export const GET_ALL_INDEX_SAMPLE_BY_CLIENT_ID = `${CURRENT_ENV}sample/get-all-index-sample-by-client-id`;
// export const GET_ALL_SAMPLES_IN_PLANT = `${CURRENT_ENV}sample/get-all-sample-in-plant`;

import React, { createContext, FunctionComponent, ReactNode, useState } from "react";

import { setLocalStorage } from "../utils";

export interface ISample {
  reference: string;
  clientName: string;
  clientRequestor: string;
  sampleLocation: string;
  touchPoint: string;
  receivedDate: string;
  clientEmail: string;
  sampleName: string;
  sampleId: string;
  isCompleted: boolean;
  sampleType: string;
  samplingDate: string;
  reportRef: string[];
  isPaid?: boolean;
}

export interface ISampleResult {
  parameterCategory: string;
  parameterMethod: string;
  parameterUnit: string;
  parameterName: string;
  productTypical: string;
  specification: ISpecification;
  previousResult: ParameterResult[];
  currentResult: ParameterResult;
}

export interface ISpecification {
  maxCritical: string;
  maxAlarm: string;
  minCritical: string;
  minAlarm: string;
}

interface IGlobalState {
  sessionId: string;
  clientId: string;
  clientName: string;
  clientEmail: string;
  clientAddress: string;
  permissionLevel: string;
  menuItems: string[];
  selectedMenu: string;
  clientDocReference: string;
  allClientSamples: ISample[];
  searchDashboard: ISearchDashboard;
}

export interface IClientProfile {
  clientId: string;
  clientName: string;
  clientAddress: string;
  clientDocReference: string;
  clientEmail: string;
}

export interface IGlobalContext {
  globalState: IGlobalState;
  handleResetGlobal: () => void;
  handleInitSideMenu: (clientProfile: IClientProfile, allSamples: ISample[]) => void;
  handleUpdateSelectedMenu: (value: string) => void;
  handleUpdateSamplesOfClient: (allSamples: ISample[]) => void;
  handleUpdateSearchDashboard: (searchDashboard: ISearchDashboard) => void;
}

export type TByCategory = "sampleId" | "sampleName";
export interface ISearchDashboard {
  searchText: string;
  byCategory: TByCategory;
  categoryItems: TByCategory[];
}

interface IGlobalProvider {
  children: ReactNode;
}

const initialState: IGlobalState = {
  sessionId: "",
  clientId: "",
  clientDocReference: "",
  clientName: "",
  clientEmail: "",
  clientAddress: "",
  permissionLevel: "",
  menuItems: ["Analysis", "Profile"], // "Enquiry",
  selectedMenu: "Analysis",
  allClientSamples: [],
  searchDashboard: {
    searchText: "",
    byCategory: "sampleId",
    categoryItems: ["sampleId", "sampleName"],
  },
};

export const GlobalContext = createContext<IGlobalContext>({
  globalState: initialState,
  handleResetGlobal: () => {},
  handleInitSideMenu: () => {},
  handleUpdateSelectedMenu: () => {},
  handleUpdateSamplesOfClient: () => {},
  handleUpdateSearchDashboard: () => {},
});

const { Provider } = GlobalContext;

export const GlobalProvider: FunctionComponent<IGlobalProvider> = ({ children }: IGlobalProvider) => {
  const [globalState, setGlobalState] = useState<IGlobalState>(initialState);

  const handleResetGlobal = () => {
    setLocalStorage("client-email", "");
    setLocalStorage("client-id", "");
    setGlobalState({ ...initialState });
  };

  const handleUpdateSelectedMenu = (value: string) => {
    setGlobalState({ ...globalState, selectedMenu: value });
  };

  const handleUpdateSamplesOfClient = (allSamples: ISample[]) => {
    setGlobalState({
      ...globalState,
      allClientSamples: [...allSamples],
    });
  };

  const handleUpdateSearchDashboard = (searchDashboard: ISearchDashboard) => {
    setGlobalState({
      ...globalState,
      searchDashboard: searchDashboard,
    });
  };

  const handleInitSideMenu = (clientProfile: IClientProfile, allSamples: ISample[]) => {
    // console.log("ctx fx", {
    //   clientProfile: clientProfile,
    //   allSamples: allSamples,
    // });
    const { clientId, clientName, clientDocReference, clientEmail, clientAddress } = clientProfile;
    setLocalStorage("client-email", clientEmail);
    setLocalStorage("client-id", clientId);
    setGlobalState({
      ...globalState,
      clientId: clientId,
      clientName: clientName,
      clientEmail: clientEmail,
      clientAddress: clientAddress,
      clientDocReference: clientDocReference,
      allClientSamples: [...allSamples],
    });
  };

  return (
    <Provider
      value={{
        globalState,
        handleResetGlobal,
        handleInitSideMenu,
        handleUpdateSelectedMenu,
        handleUpdateSamplesOfClient,
        handleUpdateSearchDashboard,
      }}>
      {children}
    </Provider>
  );
};
